<template>
    <div class="flyover" v-if="visible">
        <div class="flyover-wrapper">
            <a href="#/" @click.prevent="hide" class="close">
                <i class="fas fa-times"></i> Close
            </a>

            <div class="title">
                {{ title }}
            </div>
            <div class="details">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Flyover",
        props: {
            show: Boolean,
            title: {
                type: String,
                default: 'Line Details',
            }
        },
        data() {
            return {
                visible: false,
            }
        },
        methods: {
            hide() {
                this.visible = false;
                this.$emit('closed');
            }
        },
        created() {
            this.visible = this.show;
        },
        watch: {
            show(n) {
                this.visible = n;
            },
            visible(n) {
                this.$emit('update:show', n);
            }

        }
    }
</script>

<style scoped>


</style>
