<template>
    <span class="input-group shift-input-group">
            <input type="text" :name="name" class="shift-start" v-model="value[name]" placeholder="RDO"
                      maxlength="4" @input="setLength()">
            <input type="text" :name="name + '_length'" class="shift-length" v-model="value[name + '_length']"
                      :readonly="!value[name]" tabindex="-1">
    </span>
</template>

<script>
    /**
     * Create shift input component for entering shift start and duration in line module
     */
    export default {
        props: {
            name: '',
            value: '',
            shiftLengthDefault: ''
        },
        data() {
            return {}
        },
        methods: {
            setLength: function () {
                let length = this.shiftLengthDefault !== 99 ? this.shiftLengthDefault : 8;
                this.value[this.name + '_length'] = this.value[this.name] ? length : null;
            }
        },
        watch: {
            shiftLengthDefault() {
                this.value[this.name] !== '' ? this.value[this.name + '_length'] = this.shiftLengthDefault : '';
            }
        }
    }
</script>
