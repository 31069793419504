<template>
</template>

<script>
    export default {
        name: "Print",
        props: {
            elementId: String,
            trigger: Boolean,
            title: {
                type: String,
                default: 'bidATC',
            }
        },
        methods: {
            print() {
                const content = document.getElementById(this.elementId).innerHTML;
                let styles = '';
                for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                    styles += node.outerHTML;
                }
                const p = window.open(
                    '',
                    '',
                    `left=0,top=0,width=${this.screenWidth * 0.8},height=720,toolbar=0,scrollbars=0,status=0`);

                p.document.write(`<!DOCTYPE html>
                    <html>
                        <head>
                            ${styles}
                            <title>${this.title}</title>
                        </head>
                        <body>${content}</body>
                    </html>
                `);
                p.document.close();
                p.focus();
                p.print();
                p.onafterprint = () => {
                    p.close();
                    this.$emit('done');
                }
            },
        },
        watch: {
            trigger(n) {
                if (n !== false) this.print();
            }
        }
    }
</script>

<style scoped>

</style>
