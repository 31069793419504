<template>
    <draggable :element="'tbody'" :list="items" :options="{group: 'lines'}">
        <tr v-for="(line, i) in items" :key="line.id" :style="'background-color:' + line.bgcolor + ' !important'">
            <td class="py-0">
                <span>
                    <i class="fas fa-sort cursor-pointer"></i>
                </span>
                <span class="float-right">
                    <!--{{ crew.crew_tag }}{{ i + 1 }}-->
                    {{i + 1}}
                </span>
            </td>
            <td colspan="9" class="p-0">
                <div v-for="(detail, di) in line.details" class="row no-gutters text-center"
                     :class="{ 'border-top': line.details.length > 1 && di !== 0 }">
                    <div class="col border-right">{{ detail.start || '--' }}</div>
                    <div class="col border-right">{{ detail.su }}</div>
                    <div class="col border-right">{{ detail.mo }}</div>
                    <div class="col border-right">{{ detail.tu }}</div>
                    <div class="col border-right">{{ detail.we }}</div>
                    <div class="col border-right">{{ detail.th }}</div>
                    <div class="col border-right">{{ detail.fr }}</div>
                    <div class="col border-right">{{ detail.sa }}</div>
                    <div class="col-3 text-left pl-3">{{ line.notes }}</div>
                </div>
            </td>
            <td class="py-0">
                <i class="fas fa-copy cursor-pointer mr-1"
                   @click="duplicateLine(line, idx)"></i>
                <i class="fas fa-edit cursor-pointer mr-1"
                   @click="editLine(line, editForm)"></i>
                <i class="fas fa-trash text-danger cursor-pointer"
                   @click="deleteForm(line, 'Are you sure you want to delete line ' + (i+1) + ' from the ' + crew.name + ' crew?')"></i>
            </td>
        </tr>
    </draggable>
</template>
<script>
    import draggable from 'vuedraggable'

    export default {
        name: 'draggable-children',
        components: {
            draggable,
        },
        props: {
            items: Array,
        },
        data() {
            return {}
        },
        methods: {}
    }
</script>
<style></style>
