<template>
    <span @click="editing = true" :class="containerClass" style="min-height:15px">

        <el-tooltip content="Click to edit, enter to save.">
            <span v-if="editing !== true" class="indicator d-block w-50 mx-auto h-100" :class="{ 'pt-3': !value }">
                {{ value || '&nbsp;&nbsp;' }}
            </span>
            <input type="text"
                   :value="value"
                   :class="inputClass"
                   v-else
                   @focus="$event.target.select()"
                   @input="$emit('input', $event.target.value)"
                   @blur="$emit('blur', value);editing = false"
                   @keyup.enter="editing = null"
                   v-bind="$attrs"
                   v-focus>
        </el-tooltip>
    </span>
</template>
<script>

    export default {
        name: 'inlineInput',
        props: {
            value: [String, Number],
            inputClass: String,
            containerClass: String,
        },
        data() {
            return {
                editing: false,
            }
        },
        methods: {
        },
        directives: {
            focus: {
                inserted(el) {
                    el.focus()
                }
            }
        }
    }
</script>
<style>
    .indicator {
        border-bottom: dotted 1px grey;
    }
    .indicator:hover {
        border: dotted 1px darkgray;
        padding: 0.1em;
    }
</style>

