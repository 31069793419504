<template>
    <span>
        <label v-if="label">
            {{  label }}
        </label>
        <el-upload
            action=""
            :show-file-list="false"
            :http-request="submit"
            ref="upload">
                <n-button size="small" type="primary">Click to upload</n-button>
        </el-upload>
    </span>

</template>

<script>
import { Upload } from 'element-ui'

export default {
  name: 'upload-file',
  components: {
    [Upload.name]: Upload
  },
  props: {
    label: {
      type: String,
      default: 'Upload Files'
    }
  },
  data () {
    return {}
  },
  methods: {
    submit ($event) {
      this.$emit('submit', $event)
    }
  },
  computed: {}
}
</script>

<style scoped>

</style>
